@font-face {
  font-family: 'Rajdhani';
  font-weight: 300;
  src: local('Rajdhani'), url(./assets/Rajdhani/Rajdhani-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Rajdhani';
    font-weight: 400;
    src: local('Rajdhani'), url(./assets/Rajdhani/Rajdhani-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Rajdhani';
    font-weight: 500;
    src: local('Rajdhani'), url(./assets/Rajdhani/Rajdhani-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: 600;
  src: local('Rajdhani'), url(./assets/Rajdhani/Rajdhani-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: bold;
  src: local('Rajdhani'), url(./assets/Rajdhani/Rajdhani-Bold.ttf) format('truetype');
}
body {
  margin: 0;
  font-weight: 500;
  font-family: Rajdhani, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 575px){
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

}
@media (max-width: 370px){
  .col-ss-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-ss-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

}