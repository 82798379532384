table > tbody > tr > td, table > thead > tr > th{
    border: none;
    line-height: 1.5;
    text-align: start;
    font-size: 11px;
    padding: 8px 8px 8px 16px !important;
    
}
table > tbody > tr > td{
    font-weight: 200;
}
table > thead > tr > th{
    font-weight: 600;
    text-transform: uppercase !important;
}

.nx-table-pagination{
    padding-bottom: 1.25rem;
    margin: 0px;
}
.nx-table-pagination .table-bordered{
    border: 1px solid #dee2e6;
    margin: 0px;
}
.page-item{ padding: 0px;}
tfoot > tr > td > nav > ul.pagination{ float: right;}
tfoot > tr > td > nav { display: inline;}
.tfoot-pagination{ border-bottom: none !important;}
.pagination-select{
    height: 24px;
    padding: 2px;
    width: 40px;
    margin: 0px 8px;
    font-size: 10px;
}
.pagination-span{
    font-size: 12px;
    font-weight: 100;
    margin: 0px 4px;
}
.pagin-count{
    margin-right: 16px;
}
.page-link{
    z-index: 0 !important;
    border-color: #e3ebf3;
    padding: 2px 12px;
    margin-right: 0px;
}
.page-link:focus{
    box-shadow: none;
}
.page-item.disabled .page-link{
    color: #ddd !important;
}
.page-item.active .page-link{
    background-color: #b53da2;
    border-color: #b53da2;
}
.pagination{
    margin: 0;
}