.nx-login-form-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.nx-login-form-content{
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: 400px;
    padding: 20px;
    color: #666;
}
.nx-login-welcome{
    color: #b53da2;
    font-size: 18px;
}
.nx-login-msg-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px
}
.nx-mi-divier{
    flex: 1;
    border: 1px solid #ced4da;
    height: 1px;
}
.nx-login-divier{
    border-bottom: 1px solid #ced4da;
    margin-top: 24px;
    margin-bottom: 24px;
}
.nx-input-label{
    text-transform: uppercase;
    font-weight: 400 !important;
}
.nx-login-description-content{
    height: 100%;
    background-color: #d4e7ff;
    display: block
}
.nx-login-description-wraper{
    width: 300px;
    text-align: end;
    margin-top: 48px;
    margin-right: 24px;
    float: right;
    color: '#b53da2'
}
.nx-login-policy-btn{
    text-transform: uppercase;
    margin-bottom: 12px;
}
.nx-login-discover-btn{
    text-transform: uppercase;
    border: 1px solid #b53da2
}
.nx-login-policy-btn span{
    margin: 8px;
} 
.nx-login-description{
    background-image: url(../../assets/img/urban.png);
    /* background-color: #cccccc; Used if the image is unavailable */
    height: 50%; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
    width: 80%;
    position: absolute;
    bottom: 0;
}
@media (max-width: 576px) {
    .nx-login-description-content{
        /* display: none; */
    }
}