.ReactModal__Overlay, .ReactModal__Overlay--after-open{
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10035 /*because header has as z-index: 1034*/
}
.ReactModal__Content, .ReactModal__Content--after-open{
  position: relative !important;
  inset: 0 0 0 68px !important;
  /* margin-top: 36px; */
  max-width: 500px;
  min-width: 300px;
  /* min-height: 164px; */
  max-height: 100%
}
@media (max-width: 991px) {
  .ReactModal__Content, .ReactModal__Content--after-open{
    inset: 0 !important;
  } 
}
.btn, .form-control{
  font-size: 12px !important;
}
.btn-link{
  padding: 0px !important;
  margin: 0px 8px !important;
}

form .btn-link{
  margin: 12px 0px 0px 0px !important;
}

.nx-link{
  background: transparent;
  box-shadow: none;
  border: none;
  color: #b53da2;
}
.card, .small-box, .elevation-4, .elevation-2{
  box-shadow: none !important;
}
.spinner-border{
  /* border: .2em solid #b53da2 !important; */
  border-right-color: transparent !important;
}
.btn-success, .btn-success:focus, .btn-success:hover, .btn-success:not(:disabled):not(.disabled):active, .btn-primary, .btn-primary:focus, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active{
  background-color: #b53da2;
  border-color: #b53da2;
}

.nx-card-title{
  float: left;
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  margin: 0;
}
.nx-card-body{
  padding-bottom: 0px;
  padding-top: 0px;
}
.nx-card-header{
  background-color: transparent;
  padding: .75rem 1.25rem;
  position: relative;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
}
.nx-detail-card .nx-card-header{
  justify-content: flex-start
}
.nx-card-header .btn-link{
  float: inline-start;
  margin-left: 0px !important;
}

.nx-card-header .btn-secondary,
.nx-card-header .btn-secondary:not(:disabled):not(.disabled).active,
.nx-card-header .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  background-color: #000;
  border-color: #000;
}
.nx-card-header.btn-secondary:hover, .nx-card-header .btn-secondary:focus{
  background-color: #000000b3;
  border-color: #000000b3;
}
.nx-card-header .btn{
  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: 500;
}
.nx-page-actions{
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
}

.nx-table-search .input-group .form-control{
  border-right: 0;
  height: 30px;
}

.nx-collapse-actions-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1.25rem;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
}
.nx-collapse-input-container{
  display: flex;
  flex-direction: column;
  /* margin: 0 0.75rem; */
  min-width: 200px;
}

.nx-collapse-input-container label{
  font-size: 12px;
  font-weight: 300 !important;
  text-transform: uppercase;
}
.nx-collapse-input-container select{
  height: 26px;
}

.nx-collapse-actions-btn{
  padding: .25rem .5rem;
  font-size: 10px !important;
  line-height: 1.5;
  height: 26px;
  text-transform: uppercase;
  font-weight: 400;
}
.nx-input-error{
  display: block;
  margin-bottom: 8px;
  color: red;
  font-size: 11px;
  margin-top: 4px;
}
a, a:hover{ color: #b53da2; }
.nav.nav-tabs.nx-nav-tabs{
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 32px;
}
.nx-nav-tabs .nav-item{ display: ruby; }
/***\
fonts
***/
.nx-fw-thin{ font-weight: 200; }
.nx-fw-light{ font-weight: 300; }
.nx-fw-medium{ font-weight: 500; }
.nx-fw-semi{ font-weight: 600; }
.nx-fw-bold{ font-weight: bold; }