
#example1_paginate, #example1_filter{
    float: right
}
.table td, .table th{
    padding: 4px 8px !important;
    font-size: 11px !important;
    display: table-cell !important;
}
.table th{
    font-weight: 500;
    text-transform: uppercase;
}
.table td{
    font-weight: 400;
    color: #000;
}
td a{
    margin: 0px 8px;
}
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
    background-color: #b53da2 !important;
    box-shadow: none !important;
}
.dataTables_empty{ text-align: center !important }

.user-panel{
    border-bottom: 1px solid #ddd !important;
}
.main-sidebar{
    background-color: #fff;
}
.sidebar{
    background-image: url(../assets/img/side.png);
    background-size: cover;
    background-color: #fff;
    background-position: center center;
    position: absolute;
    width: 250px;
    overflow-y: auto;
}
.sidebar a {
    color: #b53da2 !important;
}
.sidebar a.active {
    color: #fff !important;
}

/*********************************************************************************************************
                                            FOOTER                                                                            
*********************************************************************************************************/
footer.main-footer{
    padding-right: 90px;
}
.sidebar-mini.sidebar-open footer.main-footer, .hold-transition.sidebar-mini footer.main-footer{
    padding-right: 266px;
}
.sidebar-mini.sidebar-closed.sidebar-collapse footer.main-footer{
    padding-right: 12px;
}
@media (min-width: 991px) {
    .sidebar-mini.sidebar-closed.sidebar-collapse footer.main-footer{
        padding-right: 90px;
    }
}
@media (max-width: 991px) {
    .sidebar-mini.sidebar-open footer.main-footer, .hold-transition.sidebar-mini footer.main-footer{
        padding-right: 12px;
    }
}